<template>
  <template v-if="!isAuthenticated">
    <div class="container flex align-items-center justify-content-center text-center">
      <div id="home-page-content" class="vertical-align-middle">
        <h1>Clustertool</h1>
        <h3>Versnellen van de renovatie van de Nederlandse gebouwvoorraad</h3>
        <Button :label="isAuthenticated ? 'Logout': 'Login'" class="p-3" @click="log_in_or_out" />
      </div>
    </div>
  </template>
  <welcome v-else />
</template>

<script setup>
import {useKeycloak} from "@/keycloak/authentication";
import Welcome from "@/views/Welcome";

const { login, logout, isAuthenticated } = useKeycloak();

const log_in_or_out = () => {
  if (isAuthenticated.value) {
    logout();
  } else {
    login();
  }
}

</script>

<style scoped>

.container {
  background-image: url("@/assets/renovation.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgba(255,255,255,0.7);
  background-blend-mode: lighten;
}

#home-page-content h1 {
  font: small-caps 80px Verdana;
}

#home-page-content h3 {
  font: 20px Verdana;
}

</style>
