<template>
  <div class="card">
    <DataTable
      ref="dt"
      v-model:selection="selectedRows"
      v-model:editingRows="editingRows"
      v-model:filters="filters"
      filter-display="menu"
      :global-filter-fields="globalFilterFields"
      :value="rows"
      edit-mode="cell"
      sort-mode="multiple"
      removable-sort
      paginator
      :rows="20"
      :rows-per-page-options="[5, 10, 20, 50, 100]"
      :loading="loading"
      data-key="id"
      class="p-datatable-sm"
      table-class="editable-cells-table p-frozen-column"
      table-style="min-width: 50rem"
      @cell-edit-complete="onCellEditComplete"
    >
      <template #empty> Geen data gevonden</template>
      <template #loading> Data aan het laden...</template>
      <template #header>
        <div class="table-header flex  md:justify-content-between">
          <h4>{{ description }}</h4>
          <div class="table-header flex flex-column md:flex-row md:justify-content-between">
            <!--            <span class="p-input-icon-left mr-2">-->
            <!--              <i class="pi pi-search" />-->
            <!--              <InputText v-model="filters['global'].value" placeholder="Keyword Search" />-->
            <!--            </span>-->
            <Button
              label="Voeg item toe"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="addRow"
            />
            <Button
              label="Verwijder selectie"
              icon="pi pi-trash"
              class="p-button-danger mr-2"
              :disabled="!selectedRows || !selectedRows.length"
              @click="deleteSelection"
            />
            <FileUpload
              mode="basic"
              accept=".xlsx"
              :max-file-size="10000000"
              label="ImportXlsx"
              choose-label="Import excel"
              class="p-button-info mr-2"
              :auto="true"
              :custom-upload="true"
              @select="onBeforeUploadExcel" @uploader="myUploader"
            />
            <Button
              label="Export excel"
              icon="pi pi-download"
              class="p-button-help mr-2"
              @click="exportExcel($event)"
            />
          </div>
        </div>
        <Message v-if="loadError" severity="error" @close="loadError=null">{{ loadError }}</Message>
      </template>
      <Column
        selection-mode="multiple"
        field="selected"
        style="width: 3rem"
        :exportable="false"
      />
      <Column
        v-for="col of columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :options="col.options"
        :style="`width: ${100/columns.length}%`"
        class="p-fluid"
        sortable
      >
        <template #editor="{ data, field }">
          <Dropdown
            v-if="col.options"
            v-model="data[field]"
            :options="col.options"
            option-label="value"
            option-value="value"
          />
          <InputText
            v-else
            v-model="data[field]"
            autofocus
          />
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="''"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <!--      <Column-->
      <!--        :row-editor="true"-->
      <!--        style="width:10%; min-width:8rem" body-style="text-align:center"-->
      <!--      />-->
    </DataTable>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, defineExpose, onBeforeMount} from "vue";
import FileUpload from "primevue/fileupload";
import * as XLSX from "xlsx";
import {FilterMatchMode} from 'primevue/api';
import InputText from 'primevue/inputtext';
import {warningToast} from "@/toastService";

const props = defineProps({
  rows: {
    type: Object,
    required: true,
  },
  columns: {
    type: Object,
    required: true,
  },
  dataSetName: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['addDefaultRow', 'deleteRows', 'addRow', 'addRows', 'checkRows']);

const dt = ref();

const loading = ref(true);
const columns = ref(null);
const columnOptionValues = ref(null);
const dataSetName = ref(props.dataSetName);
const description = ref(null);
const rows = ref(null);
const selectedRows = ref();
const editingRows = ref([]);
// const csvFileName = ref(null);
const loadError = ref(null);
const filters = ref();
const globalFilterFields = ref();

onBeforeMount(() => {
  description.value = props.description;
  columns.value = props.columns;
  columnOptionValues.value = [];
  let colIndex = 0;
  for (let column of columns.value) {
    if (column.options) {
      columnOptionValues.value[colIndex] = [];
      let optionIndex = 0;
      for (let option of column.options) {
        columnOptionValues.value[colIndex][optionIndex] = option.value;
        optionIndex += 1;
      }
    } else {
      columnOptionValues.value[colIndex] = null;
    }
    colIndex += 1;
  }
  rows.value = props.rows;

  filters.value = {};
  filters.value['global'] = {value: null, matchMode: FilterMatchMode.CONTAINS};
  globalFilterFields.value = [];
  columns.value.forEach(column => {
    filters.value[column.field] = {value: null, matchMode: FilterMatchMode.CONTAINS};
    globalFilterFields.value.push("name");
  });
  loading.value = false;
});

const getMaxRowIndex = () => {
  let max_index = 0;
  rows.value.forEach(row => {
    max_index = row.id > max_index ? row.id : max_index;
  });
  return max_index;
}

const addRow = () => {
  emit('addDefaultRow', getMaxRowIndex() + 1);
}

const onCellEditComplete = (event) => {
  let {data, newValue, field} = event;
  if (data[field] === '') {
    data[field] = newValue;
  } else if (typeof newValue === 'number') {
    data[field] = newValue;
  } else if (newValue.replace(/\s/g, '') !== '') {
    data[field] = newValue;
  } else {
    event.preventDefault();
  }
};

const deleteSelection = () => {
  let deleteIndices = [];
  for (let selectedRow of selectedRows.value) {
    deleteIndices.push(rows.value.indexOf(selectedRow));
  }
  // sort in decreasing order for splice
  deleteIndices.sort(function (a, b) {
    return b - a;
  });

  emit("deleteRows", deleteIndices);
};

const myUploader = () => {
  // suppress standard http request
}

const exportExcel = () => {
  const headers = columns.value.map((item) => item['field']);
  let rowsWithoutID;
  if (rows.value.length > 0) {
    rowsWithoutID = rows.value.map((row) => {
      const rowWithoutId = {};
      headers.forEach((header) => rowWithoutId[header] = row[header]);
      return rowWithoutId;
    });
  } else {
    const headerRow = {};
    headers.forEach((header) => headerRow[header] = '');
    rowsWithoutID = [headerRow]
  }
  const worksheet = XLSX.utils.json_to_sheet(rowsWithoutID);// Convert data to a worksheet

  worksheet['!cols'] = headers.map((a) => ({wch: (a ? a.toString().length : 0)}));

  const workbook = XLSX.utils.book_new(); // Create a new workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'track_record'); // Add the worksheet to the workbook

  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'}); // Generate the Excel file in memory
  const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}); // Create a blob from the buffer
  const excelFileName = `${dataSetName.value.replaceAll(" ", "_")}.xlsx`; // Set the filename

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // For Internet Explorer
    window.navigator.msSaveOrOpenBlob(excelBlob, excelFileName);
  } else {
    // For modern browsers
    const excelURL = window.URL.createObjectURL(excelBlob);
    const a = document.createElement('a');
    a.href = excelURL;
    a.download = excelFileName;
    a.click();
    window.URL.revokeObjectURL(excelURL);
  }
};

const onBeforeUploadExcel = (event) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, {type: 'array'});
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});

    // Process the imported data (e.g., store it in the component's data)
    loadData(jsonData);
  };
  reader.readAsArrayBuffer(event.files[0]);
}

const loadData = (data) => {
  // Process the imported data (e.g., store it in the component's data)
  const requiredHeaders = columns.value.map((item) => item['field']);
  let header = null;
  const dataEntries = [];
  let numberOfPostalCodesStrippedFromSpaces = 0;
  for (let rowData of data) {
    if (header == null) {
      header = rowData.map(v => v.toLowerCase().replace(/\s/g, ''));
      if (!requiredHeaders.every(colName => header.includes(colName))) {
        loadError.value = `Probleem met excel import, incorrecte kolom headers. De volgende headers zijn vereist: '${requiredHeaders}'`;
        return;
      } else {
        loadError.value = null;
      }
    } else {
      const dataEntry = {};
      header.forEach((colName, index) => {
        if (requiredHeaders.includes(colName)) {
          dataEntry[colName] = rowData[index];

          // Remove spaces from postal codes as the format in our database is `1234AB`, not `1234 AB`.
          if (colName === "postal_code" && dataEntry[colName].indexOf(" ") > -1) {
            dataEntry[colName] = dataEntry[colName].replace(/\s/g, "");
            numberOfPostalCodesStrippedFromSpaces++;
          }

          const colItem = columns.value.find(col => col.field === colName);
          if (Object.hasOwn(colItem, 'set_if_empty') && dataEntry[colName] === undefined) {
            dataEntry[colName] = colItem.set_if_empty;
          }
        }
      });
      dataEntries.push(dataEntry);
    }
  }

  if (numberOfPostalCodesStrippedFromSpaces > 0) {
    warningToast(
        "Spaties gevonden in postcode(s)",
        `Er zijn ${numberOfPostalCodesStrippedFromSpaces} postcodes gevonden die een spatie bevatten.
        Deze spaties zijn verwijderd in de tabel.`)
  }

  emit('addRows', getMaxRowIndex() + 1, dataEntries);
}

const selectErrorRows = (errorIndices) => {
  errorIndices.sort(function (a, b) {
    return a - b;
  });

  if (errorIndices.length === 0) {
    return;
  }

  // errorIndex == -1 means the error(s) do not refer to a specific row.
  // For example, when an error occurred at the server.
  if (errorIndices.length === 1 && errorIndices[0] === -1) {
    return;
  }

  selectedRows.value = [];
  for (const index of errorIndices) {
    const errorRow = rows.value.splice(index, 1)[0];
    rows.value.unshift(errorRow);
    selectedRows.value.unshift(errorRow);
  }
}

defineExpose({
  selectErrorRows
})

</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.p-dialog .product-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>