<template>
  <Dialog
    :visible="true"
    modal
    dismissable-mask
    :style="{ width: '100rem' }"
    :breakpoints="{ '1920px': '95vw' }"
    @update:visible="hideDialog"
  >
    <template #header>
      <div style="font-size: 30px; font-weight: 600">Getraind model: {{ trainingJob.name }}</div>
    </template>

    <h3>
      Kwaliteit: <span
        v-tooltip.right="qualityTooltip"
        :class="'row-badge row-' + qualityValue"
        style="font-size: 125%;"
      >
        {{ qualityDescription }}
      </span>
    </h3>

    <div v-if="showDetails">
      <p>Renovatie Concept: <strong>{{ trainingJob.training_setup.renovation_concept.name }}</strong></p>

      <p>Gebruikte track records:</p>
      <view-edit-select-table
        :rows="trainingJob.training_setup.label_data_sets"
        :columns="ld_columns"
      />

      <p>
        Training model naam: <strong>{{ trainingJob.training_setup.kfp_name }}</strong>
        <br>
        Training model versie: <strong>{{ trainingJob.training_setup.kfp_version }}</strong>
        <br>
        Berekening aangemaakt op: <strong>{{ dateTimeToString(trainingJob.created_at) }}</strong>
        <br>
        Berekening klaar op: <strong>{{ dateTimeToString(trainingJob.finished_at) }}</strong>
        <br>
        Berekening status: <strong>{{ dateTimeToString(trainingJob.last_status) }}</strong>
        <br>Voortgang: <strong>{{
          trainingJob.progress_fraction != null ?
            `${Math.floor(trainingJob.progress_fraction* 100)}%, ${trainingJob.progress_message}` : "Onbekend" }}
        </strong>
      </p>
      <div v-if="trainingJob.training_result">
        <div style="max-width: 465px">
          <DataTable
            :value="confusionRows"
            show-gridlines
            class="p-datatable-sm"
          >
            <Column
              v-for="col of columns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
              header-style="text-align:center"
              filter-header-style="text-align:center"
              body-style="text-align:center"
            />
          </DataTable>
        </div>
        <h4>Histogram</h4>
        <div style="max-width: 800px">
          <Chart type="bar" :data="histogramData" :options="histogramOptions" />
        </div>
      </div>
    </div>
    <h4>Contingenten</h4>
    <view-edit-select-table
      :rows="trainingJob.prediction_jobs"
      :columns="prediction_job_columns"
    />
    <slot />
  </Dialog>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted} from "vue";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import {getTrainingResultQuality, getHistogramValues, dateTimeToString} from '@/helpers/functions';

const props = defineProps({
  trainingJob: {
    type: Object,
    required: true,
  },
  showDetails: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const qualityValue = ref(null);
const qualityDescription = ref(null);
const qualityTooltip = ref(null);
const confusionRows = ref([]);
const positiveAddressesRow = ref({'description': 'positieve adressen'})
const negativeAddressesRow = ref({'description': 'negatieve adressen'})
const trainingResult = ref(null);
const columns = ref([
  {field: 'description', header: 'Confusion matrix'},
  {field: 'actual_positive', header: 'positief voorspeld'},
  {field: 'actual_negative', header: 'negatief voorspeld'},
]);

const histogramData = ref(null);
const histogramOptions = ref(
    {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }
);

onMounted(() => {
  [qualityValue.value, qualityDescription.value] = ['', ''];
  trainingResult.value = props.trainingJob.training_result;
  if (trainingResult.value) {
    [qualityValue.value, qualityDescription.value, qualityTooltip.value] = getTrainingResultQuality(trainingResult.value.metrics);

    confusionRows.value = [];
    confusionRows.value.push(
        Object.assign({}, positiveAddressesRow.value, trainingResult.value.metrics.confusion_matrix.positive)
    );
    confusionRows.value.push(
        Object.assign({}, negativeAddressesRow.value, trainingResult.value.metrics.confusion_matrix.negative)
    )

    let [histogram_bin_centers, histogram_bin_values] = getHistogramValues(
        0, 1, trainingResult.value.histogram.bins, trainingResult.value.histogram.x)

    histogramData.value = {
      labels: histogram_bin_centers,
      datasets: [
        {
          label: 'Adressen',
          backgroundColor: '#42A5F5',
          data: histogram_bin_values
        }
      ]
    };
  }
});

const ld_columns = ref([
  {field: 'name', header: 'Track record'},
  {field: 'renovation_concept.name', header: 'Renovatie Concept'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);

const prediction_job_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
]);

const emit = defineEmits(['close']);

const hideDialog = () => {
  emit("close");
}

</script>

<style scoped>

</style>