import {API_HOST} from "./envconfig";
import {useKeycloak} from "./keycloak/authentication";
import {errorToast, warningToast} from "@/toastService";

const {getHeaders} = useKeycloak();

/**
 * GET api data.
 * @param path The subpath to query.
 * @param params The query parameters to send.
 */
export const fetchData = async (path, params = null) => {
    const headers = getHeaders();
    headers.Accept = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        const response = await fetch(url, {headers: headers});
        return response.json();
    } catch (e) {
        console.error(url);
        console.error(e);
        errorToast("Onverwachte fout", "Er is een onverwachte fout opgetreden. Probeer het later nog eens.")
        window.location.reload();
    }
    return null;
}

/**
 * GET api file.
 * @param path The subpath to query.
 * @param params The query parameters to send.
 */
export const fetchFile = async (path, params = null) => {
    const headers = getHeaders();
    headers["Accept"] = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        const response = await fetch(url, {headers: headers});
        const blob = await response.blob();
        return window.URL.createObjectURL(new Blob([blob]));
    } catch (e) {
        console.error(url);
        console.error(e);
        errorToast("Onverwachte fout", "Er is een onverwachte fout opgetreden. Probeer het later nog eens.")
        window.location.reload();
    }
    return null;
}

/**
 * POST api data.
 * @param path The subpath to query.
 * @param payload The data to send in the body.
 * @param params The query parameters to send.
 */
export const postData = async (path, payload, params = null) => {
    const headers = getHeaders();
    headers["Content-Type"] = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
        });

        if (response && response.status === 413) {
            warningToast("Dataset te groot", "De dataset die u probeert te uploaden is te groot. Probeer het opnieuw met een kleinere dataset.");
            return null;
        }

        return response;
    } catch (e) {
        console.error(e);
        errorToast("Onverwachte fout", "Er is een onverwachte fout opgetreden. Probeer het later nog eens.")
        window.location.reload();
    }
    return null;
}

/**
 * PUT api data.
 * @param path The subpath to query.
 * @param payload The data to send in the body.
 * @param params The query parameters to send.
 */
export const putData = async (path, payload, params = null) => {
    const headers = getHeaders();
    headers["Content-Type"] = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        return await fetch(url, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
        });
    } catch (e) {
        console.error(e);
        errorToast("Onverwachte fout", "Er is een onverwachte fout opgetreden. Probeer het later nog eens.")
        window.location.reload();
    }
    return null;
}

/**
 * DELETE api data.
 * @param path The subpath to query.
 * @param payload The data to send in the body.
 * @param params The query parameters to send.
 */
export const deleteData = async (path, payload = {}, params = null) => {
    const headers = getHeaders();
    headers["Content-Type"] = "application/json";
    const url = new URL(`${API_HOST}/${path}`);
    if (params) {
        Object.keys(params).forEach((key) =>
            url.searchParams.append(key, params[key])
        );
    }
    try {
        return await fetch(url, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(payload), // body data type must match "Content-Type" header
        });
    } catch (e) {
        console.error(e);
        errorToast("Onverwachte fout", "Er is een onverwachte fout opgetreden. Probeer het later nog eens.")
        window.location.reload();
    }
    return null;
}
