<template>
  <i-o-table
    v-model:columns="columns"
    v-model:rows="measures"
    description="Renovatie maatregelen"
    :data-set-name="dataSetName"
    @deleteRows="deleteMeasures"
    @addDefaultRow="addDefaultMeasure"
    @addRow="addMeasureFromCsv"
  />
</template>

<script setup>
import {ref, defineProps, onBeforeMount} from "vue";
import {RenovationMeasure, building_parts} from "@/types";
import IOTable from "@/components/base-components/IOTable.vue";

const props = defineProps({
  renovationMeasures: {
    type: Object,
    required: true,
  },
  dataSetName: {
    type: String,
    required: true,
  }
});

const measures = ref(props.renovationMeasures);
const dataSetNAme = ref(props.renovationMeasures);

const building_part_options = ref([]);
const setBuildingParts = () => {
  for (let building_part_key in building_parts) {
    building_part_options.value.push({
      label: building_part_key, value: building_parts[building_part_key]
    })
  }
};
setBuildingParts();

const columns = ref([
  {field: 'name', header: 'Naam', options: null},
  {field: 'description', header: 'Beschrijving', options: null},
  {field: 'building_part', header: 'Gebouwonderdeel', options: building_part_options}
]);

onBeforeMount(() => {
  measures.value = props.renovationMeasures;
})

const deleteMeasures = (deleteIndices) => {
  for (let index of deleteIndices) {
    measures.value.splice(index, 1);
  }
};

const addDefaultMeasure = (index) => {
  measures.value.unshift(
      new RenovationMeasure(index, "naam", "beschrijving", building_part_options.value[0].value)
  );
};

const addMeasureFromCsv = (index, valuesDict) => {
  measures.value.push(
      new RenovationMeasure(index, valuesDict['name'], valuesDict['description'], valuesDict['building_part'])
  );
};

</script>

<style lang="scss" scoped>

</style>