<script setup>

</script>

<template>
  <ProgressSpinner />
</template>

<style scoped>

</style>