<template>
  <div class="grid p-2">
    <Card class="w-full mt-1">
      <template #content>
        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
          <h3>Standaard training model versie per kubeflow pipeline</h3>
        </div>
        <table>
          <tr>
            <th class="text-left w-15rem">Model naam</th>
            <th class="text-left">Standaard versie</th>
          </tr>
          <tr v-for="(versions, model_name) in trainingModelVersions" :key="model_name">
            <td>{{ model_name }}</td>
            <Dropdown
              v-model="selectedModelVersions[model_name]"
              :options="versions"
              option-label="description"
              option-value="name"
              input-id="training-model-versie"
              class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
                      appearance-none outline-none focus:border-primary"
              @update:model-value="onUpdateDropdown(model_name)"
            />
          </tr>
        </table>
      </template>
    </Card>
  </div>
</template>

<script setup>
import {defineEmits, ref} from "vue";
import {fetchData} from "@/api";

const selectedModelVersions = ref({});
const trainingModelVersions = ref({});
const emit = defineEmits(["update_version"]);

const queryTrainingModels = async () => {
  const resp = await fetchData("training/models");
  if (resp.length === 0) {
    return
  }
  for (const model of resp) {
    trainingModelVersions.value[model.name] = [];
    selectedModelVersions.value[model.name] = "";
    for (let version of model.kfp_versions) {
      let description;
      if (model.kfp_version_default === version) {
        description = version + " (default)";
        selectedModelVersions.value[model.name] = version;
      } else {
        description = version;
      }
      trainingModelVersions.value[model.name].push({name: version, description: description})
    }
  }
};
queryTrainingModels();

const onUpdateDropdown = (model_name) => {
  emit("update_version", {model_name: model_name, version_name: selectedModelVersions.value[model_name]});
};
</script>