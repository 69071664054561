<template>
  <Card>
    <template #title> Manage Organizations</template>
    <template #content>
      <Button label="Create" icon="pi pi-plus" @click="createOrganization" />

      <DataTable :value="organizations">
        <Column field="id" header="ID" />
        <Column field="name" header="Name" />
        <Column field="type" header="Type" />
        <Column>
          <template #body="slotProps">
            <Button icon="pi pi-pencil" @click="editOrganization(slotProps.data)" />
            <Button icon="pi pi-trash" @click="deleteOrganization(slotProps.data)" />
          </template>
        </Column>
      </DataTable>

      <Dialog v-model:visible="displayDialog" header="Organization Details" modal :closable="false" width="600">
        <div class="flex flex-column gap-3">
          <div class="flex flex-column gap-1">
            <label for="name">Name</label>
            <InputText id="name" v-model="organization.name" />
          </div>
          <div class="flex flex-column gap-1">
            <label for="type">Type</label>
            <Dropdown id="type" v-model="organization.type" :options="types" option-label="name" option-value="name" />
          </div>
          <div>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="cancel" />
            <Button label="Save" icon="pi pi-check" @click="saveOrganization" />
          </div>
        </div>
      </Dialog>
    </template>
  </Card>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import {deleteData, fetchData, postData, putData} from "@/api";
import {loadOrganizations} from "@/service/OrganizationService";
import {errorToast} from "@/toastService";

const displayDialog = ref(false);
const organization = ref({id: '', name: '', type: ''});
const organizations = ref([]);
const types = [
  {name: 'Onderzoeksbedrijf'},
  {name: 'Bouwbedrijf'},
  {name: 'Intermediair'}
];

onMounted(async () => {
  organizations.value = await loadOrganizations()
});

/**
 * Save organization to API. Either post or put depending if it's a new organization or an existing one being edited.
 * @returns {Promise<void>}
 */
const saveOrganization = async () => {
  let response = null
  if (organization.value.id) {
    response = await putData('auth/organizations/' + organization.value.id, organization.value)
  } else {
    response = await postData('auth/organizations/', organization.value)
  }
  if (response.ok) {
    displayDialog.value = false
  } else {
    errorToast("Fout opgetreden", "Er is een fout opgetreden tijdens het opslaan van de organisatie: " + response.statusText)
  }
  organizations.value = await loadOrganizations()
};

const deleteOrganization = async (org) => {
  // Implement fetch call here to delete organization
  const response = await deleteData('auth/organizations/' + org.id)
  if (response.ok) {
    displayDialog.value = false
  } else {
    errorToast("Fout opgetreden", "Er is een fout opgetreden tijdens het verwijderen van de organisatie: " + response.statusText)
  }
  organizations.value = await loadOrganizations()
};

const editOrganization = (org) => {
  organization.value.id = org.id;
  organization.value.name = org.name;
  organization.value.type = org.type;
  displayDialog.value = true;
};

/**
 * Create a new organization.
 */
const createOrganization = () => {
  organization.value.id = null;
  organization.value.name = '';
  organization.value.type = '';
  displayDialog.value = true;
};

const cancel = () => {
  displayDialog.value = false;
};
</script>
