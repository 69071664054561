<template>
  <div class="flex flex-column h-full p-2">
    <div id="menubar">
      <Menubar
        :model="isAuthenticated ? items_logged_in : items"
      >
        <template #start>
          <img src="./assets/logo.png">
        </template>
        <template #end>
          <Menubar
            v-if="isAuthenticated"
            :model="account_menu"
            class="user-menu"
          />
        </template>
      </Menubar>
    </div>

    <router-view
      class="router-view flex-grow-1"
    />

    <Toast />
  </div>
</template>

<script setup>
import {computed, onBeforeMount, ref} from 'vue';
import {useKeycloak} from "./keycloak/authentication";

const {isAuthenticated, hasRole, getKeycloak, logout} = useKeycloak();
const isAdmin = computed(() => hasRole('admin'));

const firstName = ref();
const lastName = ref();

onBeforeMount(() => {
  if (isAuthenticated.value) {
    const kc = getKeycloak();
    firstName.value = kc.tokenParsed.given_name
    lastName.value = kc.tokenParsed.family_name
  }
})

const items = ref([
  {
    label: 'Home',
    to: '/',
    icon: 'pi pi-home',
  },
]);

const items_logged_in = ref([
  {
    label: 'Home',
    to: '/',
    icon: 'pi pi-home',
  },
  {
    label: 'Gegevens laden',
    to: '/load_data',
    icon: 'pi pi-upload',
  },
  {
    label: 'Model trainen',
    to: '/train_algorithm',
    icon: 'pi pi-chart-line',
  },
  {
    label: 'Contingent bepalen',
    to: '/determine_contingent',
    icon: 'pi pi-filter',
  },
  {
    label: 'Resultaten',
    to: '/map_results',
    icon: 'pi pi-map',
  },
  {
    label: 'Admin',
    icon: 'pi pi-prime',
    visible: isAdmin,
    items: [
      {
        label: 'Users',
        to: '/admin/users',
        icon: 'pi pi-user',
      },
      {
        label: 'Organizations',
        to: '/admin/organizations',
        icon: 'pi pi-building',
      },
      {
        label: 'Settings',
        to: '/admin/settings',
        icon: 'pi pi-cog',
      },
      {
        label: 'Debug',
        to: '/admin/debug',
        icon: 'pi pi-code',
      },
    ]
  },

]);

const account_menu = ref([
  {
    label: '',
    icon: 'pi pi-user',
    items: [
      {
        label: 'Settings',
        to: '/settings',
        icon: 'pi pi-cog',
      },
      {
        separator: true
      },
      {
        label: 'Logout',
        command: logout,
        icon: 'pi pi-sign-out',
      },
    ],
  }
]);

</script>

<style lang="scss">

html, body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%
}

.router-view {
  width: 100%
}

.user-menu.p-menubar {
  border: none !important;
}

.user-menu.p-menubar .p-submenu-list {
  z-index: 101;
  right: 0;
}

.router-link-active {
  font-weight: bold;
}

.row-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.row-badge.row-good {
  background: #C8E6C9;
  color: #256029;
}

.row-badge.row-bad {
  background: #FFCDD2;
  color: #C63737;
}

.row-badge.row-medium {
  background: #FEEDAF;
  color: #8A5340;
}

.p-card.p-component {
  width: 100%;
  margin-top: 1em;
  border-radius: 8px;
  border: 0.15rem solid darkgray;
  box-shadow: 0 5px 10px gainsboro;
}

.p-tooltip.p-component {
  max-width: 25em;
}

.p-datatable-loading-overlay.p-component-overlay {
  background-color: white;
}

.p-progress-spinner-circle {
  stroke: black !important;
}

</style>
