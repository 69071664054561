export function getTrainingResultQuality(metrics) {
    let qualityValue, qualityDescription, qualityTooltip;
    if (!metrics) {
        qualityValue = 'unknown';
        qualityDescription = '';
        qualityTooltip = '';
    } else if (metrics.accuracy_class === 2) {
        qualityValue = 'good';
        qualityDescription = 'goed';
        qualityTooltip = 'De resultaten van dit model zijn goed, echter kunnen er altijd nog fouten in de ' +
            'voorspelling zitten dus blijf kritisch kijken naar de uitkomsten bij gebruik.';
    } else if (metrics.accuracy_class === 1) {
        qualityValue = 'medium';
        qualityDescription = 'redelijk';
        qualityTooltip = 'De resultaten van dit model zijn redelijk, maar niet goed: mogelijk is het voor beperkte ' +
            'doeleinden bruikbaar maar houd een slag om de arm en neem uitkomsten niet klakkeloos over zonder ' +
            'verdere analyse. Neem contact op met TNO voor informatie hoe de prestatie te verbeteren.';
    } else {
        qualityValue = 'bad';
        qualityDescription = 'slecht';
        qualityTooltip = 'Deze label data set resulteert in een onbetrouwbaar model met slechte prestaties. ' +
            'Dit kan niet gebruikt worden om contingenten te maken. ' +
            'Neem contact op met TNO voor informatie hoe de prestatie te verbeteren.';
    }
    return [qualityValue, qualityDescription, qualityTooltip];
}

export function getHistogramValues(minValue, maxValue, nrOfBins, values) {
    let binCenters = new Array(nrOfBins).fill(0);
    let binValues = new Array(nrOfBins).fill(0);

    for (let iBin = 0; iBin < nrOfBins; iBin++) {
        binCenters[iBin] = ((0.5 + iBin) * (maxValue - minValue) / nrOfBins).toFixed(2);
    }

    let binNumber;
    for (let value of values) {
        binNumber = Math.floor(nrOfBins * (value - minValue) / (maxValue - minValue));
        binValues[binNumber] += 1;
    }

    return [binCenters, binValues]
}

export const filters = {
    datetime_nl(value) {
        const options = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            year: 'numeric'
        };
        return new Intl.DateTimeFormat('nl', options).format(new Date(value));
    }
}

// export const convertDateTimeProps = (obj) => {
//     if (Array.isArray(obj)) {
//         for (const item of Object.values(obj)) {
//             convertDateTimeProps(item);
//         }
//     } else {
//         for (const [key, prop] of Object.entries(obj)) {
//             if (isNaN(prop) && !isNaN(new Date(prop))) {
//                 obj[key] = filters.datetime_nl(prop);
//             } else if (prop) {
//                 if (prop.constructor === Object || Array.isArray(prop)) {
//                     convertDateTimeProps(prop);
//                 }
//             }
//         }
//     }
// }

export const dateTimeToString = (dateTimeString) => {
    if (isNaN(dateTimeString) && !isNaN(new Date(dateTimeString))) {
        return filters.datetime_nl(dateTimeString);
    }
    return dateTimeString;
}

export const getTrainingQualities = (training_jobs) => {
    const qualities = [];
    const displayQualities = [];
    for (const trainingJob of training_jobs) {
        let [qualityValue, qualityDescription, qualityTooltip] = ['', '', '']
        if (trainingJob.training_result) {
            [qualityValue, qualityDescription, qualityTooltip] = getTrainingResultQuality(trainingJob.training_result.metrics);
        }
        qualities[trainingJob.id] = qualityValue;
        displayQualities[trainingJob.id] = qualityDescription;
    }
    return {
        name: "Kwaliteit",
        values: qualities,
        display_values: displayQualities
    };
};

export function csvToArray(str) {
    let arr = [];
    let quote = false;  // 'true' means we're inside a quoted field

    // Iterate over each character, keep track of current row and column (of the returned array)
    for (let row = 0, col = 0, c = 0; c < str.length; c++) {
        let cc = str[c], nc = str[c + 1];        // Current character, next character
        arr[row] = arr[row] || [];             // Create a new row if necessary
        arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary

        // If the current character is a quotation mark, and we're inside a
        // quoted field, and the next character is also a quotation mark,
        // add a quotation mark to the current column and skip the next character
        if (cc === '"' && quote && nc === '"') {
            arr[row][col] += cc;
            ++c;
            continue;
        }

        // If it's just one quotation mark, begin/end quoted field
        if (cc === '"') {
            quote = !quote;
            continue;
        }

        // If it's a comma and we're not in a quoted field, move on to the next column
        if (cc === ',' && !quote) {
            ++col;
            continue;
        }

        // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
        // and move on to the next row and move to column 0 of that new row
        if (cc === '\r' && nc === '\n' && !quote) {
            ++row;
            col = 0;
            ++c;
            continue;
        }

        // If it's a newline (LF or CR) and we're not in a quoted field,
        // move on to the next row and move to column 0 of that new row
        if (cc === '\n' && !quote) {
            ++row;
            col = 0;
            continue;
        }
        if (cc === '\r' && !quote) {
            ++row;
            col = 0;
            continue;
        }

        // Otherwise, append the current character to the current column
        arr[row][col] += cc;
    }
    return arr;
}

export function exportFileFromBloburl(blobUrl, fileName) {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
}

export function roundToNrOfDecimals(number, numberOfDecimals) {
    return Math.round(number * Math.pow(10, numberOfDecimals)) / Math.pow(10, numberOfDecimals);
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
