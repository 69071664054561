<template>
  <div class="card">
    <!-- Dialog width is 95% of the screen width, but at most 100rem -->
    <Dialog
      :visible="true"
      :modal="true"
      :closable="false"
      :style="{ width: '100rem' }"
      :breakpoints="{ '1920px': '95vw' }"
    >
      <template #header>
        <h3>
          <span v-if="isNewConcept">Voeg een renovatie concept toe</span>
          <span v-else>Pas een renovatie concept aan</span>
          <help-sidebar
            v-if="helpReference"
            :help-reference="helpReference"
          />
        </h3>
      </template>

      <div class="field grid">
        <label for="naam" class="col-fixed">Naam</label>
        <div class="col">
          <input
              id="naam"
              v-model="name"
              type="text"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round
           appearance-none outline-none focus:border-primary w-20rem"
          >
        </div>
      </div>

      <div class="field">
        <renovation-measures-table
            v-model:renovation-measures="rc.renovation_measures"
            :data-set-name="'renovatieconcept_' + name"
        />
      </div>

      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button label="Annuleer" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
          <Button label="Bevestig" icon="pi pi-check" autofocus :disabled="buttonAdviceText != null" @click="addUpdateConcept" />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed} from "vue";
import HelpSidebar from "@/components/help/HelpSidebar"
import {RenovationConcept} from "@/types";
import RenovationMeasuresTable from "@/components/renovation_concept/RenovationMeasuresTable";

const props = defineProps({
  renovationConcept: {
    type: RenovationConcept,
    required: false,
    default: null,
  },
  helpReference: {
    type: String,
    required: false,
    default: null
  }
});

const rc = ref(props.renovationConcept);
const isNewConcept = ref(false);
if (!rc.value) {
  isNewConcept.value = true;
  rc.value = new RenovationConcept();
}
const name = ref(null);

const emit = defineEmits(['add', 'update', 'cancel']);

onMounted(() => {
  name.value = rc.value.name;

  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') emit('cancel');
  });
});


const hideDialog = () => {
  emit("cancel");
};

const addUpdateConcept = () => {
  rc.value.name = name;
  if (isNewConcept.value) {
    emit("add", rc.value);
  } else {
    emit("update", rc.value);
  }
};

const buttonAdviceText = computed(() => {
  if (name.value.replace(/\s/g, '') === '') {
    return "Vul naam in";
  } else if (rc.value.renovation_measures.length === 0) {
    return "Voeg renovatie maatregelen toe";
  }
  return null;
});

</script>

<style scoped>

</style>