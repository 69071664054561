<template>
  <div class="pb-2">
    <Card>
      <template #title>
        Training: definiëer en start berekening
        <help-sidebar help-reference="Training: definieer en start berekening" />
      </template>
      <template #content>
        <label for="naam">Naam</label>
        <input
          id="naam"
          v-model="trainModelName"
          type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-full"
        >
        <h4>Selecteer een renovatie concept</h4>
        <view-edit-select-table
          v-model:selected-row="selectedRenovationConcept"
          :rows="renovationConcepts"
          :columns="columns"
          :selectable-row="true"
          :custom-actions="renovationConceptTableActions"
          :loading="loadingRenovationConcepts"
          @select="onSelectRenovationConcept"
        />
        <h4>Selecteer een track record</h4>
        <view-edit-select-table
          v-model:selected-row="selectedLabelDataSet"
          :rows="labelDataSets"
          :columns="ld_columns"
          :selectable-row="true"
          :custom-actions="labelDataSetTableActions"
          :loading="loadingTrackRecords"
          @select="onSelectLabelDataSet"
        />
        <div v-if="hasRole('admin')">
          <h4>Selecteer training model en versie</h4>
          <Dropdown
            v-model="selectedTrainingModelName"
            :options="trainingModels"
            option-label="name"
            option-value="name"
            input-id="training-model"
            class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary"
          />
          <label for="training-model" class="ml-2">Selecteer training model</label>
          <br>
          <Dropdown
            v-model="selectedTrainingModelVersion"
            :options="trainingModelVersions[selectedTrainingModelName]"
            option-label="description"
            option-value="name"
            input-id="training-model-versie"
            class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary"
          />
          <label for="training--model-versie" class="ml-2">Selecteer model versie</label>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            class="flex align-items-center justify-content-center m-2"
            label="Start berekening"
            icon="pi pi-play"
            :disabled="buttonAdviceText != null"
            @click="onStartCalculation"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Card>
    <Card>
      <template #title>
        Training: lopende berekeningen en resultaten
        <help-sidebar help-reference="Training resultaten" />
      </template>
      <template #content>
        <view-edit-select-table
          :rows="trainingJobs"
          :columns="trainingJobColumns"
          :page-size="10"
          :deletable-row="true"
          :sort-option="new DataTableSortOption('updated', DataTableSortOrder.DESCENDING)"
          :badges="trainingResultsQualities"
          :custom-actions="jobActions"
          :loading="loadingTrainingJobs"
          @delete="onDeleteTrainingJob"
        />
      </template>
    </Card>
    <view-renovation-concept-details
      v-if="renovationConceptToView!==null"
      :renovation-concept="renovationConceptToView"
      @close="renovationConceptToView=null"
    />
    <view-label-data-set-details
      v-if="labelDataSetToView!==null"
      :label-data-set="labelDataSetToView"
      @close="labelDataSetToView=null"
    />
    <view-training-details
      v-if="trainingJobToView!==null"
      :training-job="trainingJobToView"
      :show-details="!beforeDeleteCheck"
      @close="trainingJobToView=null"
    >
      <div v-if="beforeDeleteCheck">
        <Message severity="warn" :closable="false">
          Na 'Bevestig' worden de Contingenten hierboven ook verwijderd!
        </Message>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            label="Annuleer" icon="pi pi-times" class="p-button-text"
            @click="trainingJobToView=null; beforeDeleteCheck=false;"
          />
          <Button
            label="Bevestig" icon="pi pi-check" autofocus
            @click="deleteTrainingJob(trainingJobToView.id, trainingJobToView.name)"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">
            Getraind model verwijderen?
          </div>
        </div>
      </div>
    </view-training-details>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {deleteData, fetchData, postData} from "@/api";
import {DataTableSortOption, DataTableSortOrder, TrainingSetup} from "@/types";
import {getTrainingQualities} from '@/helpers/functions';
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import ViewTrainingDetails from "@/components/training/ViewTrainingDetails";
import ViewLabelDataSetDetails from "@/components/label_data_set/ViewLabelDataSetDetails";
import ViewRenovationConceptDetails from "@/components/renovation_concept/ViewRenovationConceptDetails";
import HelpSidebar from "@/components/help/HelpSidebar"
import {useKeycloak} from "@/keycloak/authentication";
import {errorToast, toast, successToast, infoToast} from "@/toastService";

const {hasRole} = useKeycloak();

const trainModelName = ref("");

const loadingRenovationConcepts = ref(true);
const renovationConcepts = ref([]);
const selectedRenovationConcept = ref(null);
const renovationConceptToView = ref(null);

const loadingTrackRecords = ref(true);
const labelDataSets = ref([]);
const selectedLabelDataSet = ref(null);
const labelDataSetToView = ref(null);

const loadingTrainingJobs = ref(true);
const trainingJobs = ref([]);

const trainingModels = ref([]);
const trainingModelVersions = ref({});
const selectedTrainingModelName = ref(null);
const selectedTrainingModelVersion = ref(null);

const trainingJobToView = ref(null);
const beforeDeleteCheck = ref(false);
const trainingResultsQualities = ref(null);

const columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);

const ld_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'renovation_concept.name', header: 'Renovatie Concept'},
  {field: 'nr_of_entries', header: 'Adressen'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);

const trainingJobColumns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
  {field: 'progress_fraction', header: 'Voortgang', format: "fraction_is_percentage"}
]);

const queryRenovationConcepts = async () => {
  renovationConcepts.value = await fetchData("renovation_concept/");
}
queryRenovationConcepts().then(() => loadingRenovationConcepts.value = false);


const onViewRenovationConceptDetails = async (renovationConceptId) => {
  renovationConceptToView.value = await fetchData("renovation_concept/" + renovationConceptId);
};

const onSelectRenovationConcept = (renovationConcept) => {
  selectedRenovationConcept.value = renovationConcept
}

const queryLabelDataSets = async () => {
  labelDataSets.value = await fetchData("label_data_set/");

}
queryLabelDataSets().then(() => loadingTrackRecords.value = false);

const onViewLabelDataSetDetails = async (labelDataSetId) => {
  labelDataSetToView.value = await fetchData("label_data_set/" + labelDataSetId);
};

const onSelectLabelDataSet = (labelDataSet) => {
  selectedLabelDataSet.value = labelDataSet
}

const buttonAdviceText = computed(() => {
  if (selectedTrainingModelVersion.value == null) {
    return "Versie voor het training model is niet gezet, neem contact op met de admin.";
  } else if (trainModelName.value.replace(/\s/g, '') === '') {
    return "Vul naam in";
  } else if (!selectedRenovationConcept.value) {
    return "Selecteer een renovatie concept";
  } else if (!selectedLabelDataSet.value) {
    return "Selecteer een track record";
  }
  return null;
});

const onStartCalculation = async () => {
  let ts = new TrainingSetup(
      trainModelName.value,
      selectedRenovationConcept.value.id,
      selectedLabelDataSet.value.id,
      selectedTrainingModelName.value,
      selectedTrainingModelVersion.value,
  )

  infoToast("Training aanmaken...")

  // create training setup in db
  const ts_post = await postData("training/setup", ts);
  const ts_resp = await ts_post.json();

  if (ts_resp === null) {
    return;
  }
  // create and start training job
  const resp = await postData("training/job", {
    training_setup_id: ts_resp.id,
  });

  if (resp.ok) {
    successToast(
        "Training berekening '" + trainModelName.value + "' toegevoegd",
        "Renovatie concept: '" + selectedRenovationConcept.value.name + "'"
        + "\n\nTrack record: '" + selectedLabelDataSet.value.name + "'"
    );
  } else {
    errorToast(
        "Training berekening '" + trainModelName.value + "' kan niet gecreëerd worden",
        "Renovatie concept: '" + selectedRenovationConcept.value.name + "'"
        + "\n\nTrack record: '" + selectedLabelDataSet.value.name + "'"
    );
  }

  trainModelName.value = '';
  await queryTrainingJobs();
}

const queryTrainingJobs = async () => {
  trainingJobs.value = await fetchData("training/job");
  trainingResultsQualities.value = getTrainingQualities(trainingJobs.value);
};
queryTrainingJobs().then(() => loadingTrainingJobs.value = false);

const queryTrainingModels = async () => {
  trainingModels.value = await fetchData("training/models");
  if (trainingModels.value.length === 0) {
    return;
  }
  selectedTrainingModelName.value = trainingModels.value[0].name;
  selectedTrainingModelVersion.value = trainingModels.value[0].kfp_version_default;
  if (hasRole('admin')) {
    for (const model of trainingModels.value) {
      trainingModelVersions.value[model.name] = [];
      for (let version of model.kfp_versions) {
        let description;
        if (model.kfp_version_default === version) {
          description = version + " (default)";
        } else {
          description = version;
        }
        trainingModelVersions.value[model.name].push({name: version, description: description});
      }
    }
  } else if (selectedTrainingModelVersion.value === null) {
    errorToast("Training model versie niet gezet.", "Neem contact op met de admin.");
  }
};
queryTrainingModels();


const onDeleteTrainingJob = async (trainingJobId) => {
  const resp = await fetchData("training/job/" + trainingJobId);
  if (resp.prediction_jobs.length > 0) {
    trainingJobToView.value = resp;
    beforeDeleteCheck.value = true;
  } else {
    infoToast("Training verwijderen...")
    await deleteTrainingJob(trainingJobId, resp.name);
  }
};

const deleteTrainingJob = async (trainingJobId, jobName) => {
  const del_resp = await deleteData("training/job/" + trainingJobId);
  await queryTrainingJobs();

  if (del_resp.ok) {
    successToast("Getrained model '" + jobName + "' verwijderd");
  } else {
    errorToast("Getrained model '" + jobName + "' kan niet verwijderd worden");
  }
  trainingJobToView.value = null;
  beforeDeleteCheck.value = false;
};

const onCheckStatus = async (trainingJobId) => {
  const training_job = await fetchData("training/job/" + trainingJobId);

  const severity_map = {
    'Succeeded': 'success',
    'Failed': 'error'
  }
  let severity = 'warn'
  if (training_job.last_status in severity_map) {
    severity = severity_map[training_job.last_status];
  }

  // Create detail message for toast
  let message = "Aangemaakt: " + formatDateTime(training_job.created_at);
  if (training_job.finished_at && Date.parse(training_job.finished_at) > 0) {
    message = "Afgerond: " + formatDateTime(training_job.finished_at)
  }
  if (training_job.scheduled_at && Date.parse(training_job.scheduled_at) > 0) {
    message = "Gepland: " + formatDateTime(training_job.scheduled_at);
  }

  toast(severity, "Training status: " + training_job.last_status, message)

  await queryTrainingJobs();
};

function formatDateTime(dateTimeString) {
  const date = new Date(new Date(Date.parse(dateTimeString)));
  return date.toLocaleString('nl-NL');
}

const onViewTrainingDetails = async (trainingJobId) => {
  trainingJobToView.value = await fetchData("training/job/" + trainingJobId);
};

const jobIsRunning = (trainingJobId) => {
  for (const trainingJob of trainingJobs.value) {
    if (trainingJob.id === trainingJobId) {
      return trainingJob.last_status.toUpperCase() === 'CREATED' || trainingJob.last_status.toUpperCase() === 'RUNNING';
    }
  }
  return false;
};

const renovationConceptTableActions = [
  {
    id: 1,
    icon: 'pi pi-eye',
    visible: () => true,
    handler: onViewRenovationConceptDetails,
    tooltip: 'Bekijk details'
  }
];

const labelDataSetTableActions = [
  {
    id: 1,
    icon: 'pi pi-eye',
    visible: () => true,
    handler: onViewLabelDataSetDetails,
    tooltip: 'Bekijk details'
  }
];

const jobActions = [
  {
    id: 1,
    icon: 'pi pi-eye',
    visible: () => true,
    handler: onViewTrainingDetails,
    tooltip: 'Bekijk details'
  },
  {
    id: 2,
    icon: 'pi pi-sync',
    visible: jobIsRunning,
    handler: onCheckStatus,
    tooltip: 'Check status'
  }
];

</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  p {
    padding: .5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color .2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}
</style>