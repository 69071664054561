<template>
  <Card>
    <template #title>
      Welkom bij de Clustertool
    </template>
    <template #content>
      <p>
        U bent zojuist succesvol ingelogd en in deze uitleg zullen we u meenemen langs de stappen die nodig zijn voor het vormen van een contingent voor uw verduurzamingsoplossing. Het gevormde contingent is een verzameling van adressen (woningen) waarop uw verduurzamingsoplossing succesvol kan worden toegepast. Dit contingent kan worden gevormd voor heel Nederland, een deel van Nederland of voor een specifieke plek of stad.
      </p>
      <p>
        Deze uitleg neemt u mee door de vier globale stappen die u zult volgen om tot het gewenste resultaat te komen. Gedetailleerde informatie over wat er per stap moet gebeuren, welke informatie moet worden aangeleverd en in welk format dit moet worden aangeleverd zal door middel van additionele informatieknoppen worden uitgelegd op de desbetreffende pagina’s.
      </p>

      <p>Het bepalen van een contingent gaat als volgt (volg de tabbladen aan de bovenkant van de pagina):</p>

      <ol>
        <li>De eerste stap is <b>Gegevens laden</b>. Om een contingent te bepalen wordt begonnen met het definiëren van het renovatieconcept en het uploaden van de track record met adressenlijst die bij deze verduurzamingsoplossing hoort. De eerste stap is het invoeren van een renovatieconcept. Dit kan één losse maatregel zijn, maar dit kan ook een concept zijn die uit meerdere maatregelen bestaat. Vervolgens moet er een track record worden toegevoegd voor het gedefinieerde concept. De track record bevat een lijst van adressen waarop de verduurzamingsoplossing al eerder succesvol is toegepast. Hulp over de te nemen stappen bij het tab Gegevens laden is beschikbaar op de pagina aan de hand van voorbeelden en informatieknoppen.</li>
        <li>De volgende stap is <b>Model trainen</b>. In deze stap wordt op basis van de aangeleverde track record data een model getraind voor het verduurzamingsconcept aan de hand van berekeningen. Dit model kan worden gebruikt om in de volgende stap een voorspelling te doen over op welke adressen het concept nog meer toepasbaar is, en dus welke woningen binnen uw contingent vallen. Op deze pagina kunt u berekeningen starten om een model te trainen, de status van lopende berekeningen bekijken en reeds uitgevoerde berekeningen terugvinden.</li>
        <li>De derde stap is <b>Contingent bepalen</b>. Aan de hand van het getrainde model kan in deze stap een voorspelling worden gedaan over op welke andere adressen de verduurzamingsoplossing nog meer succesvol kan worden toegepast in de toekomst. U kunt zelf de locatie selecteren waarvoor u het contingent wilt bepalen (heel Nederland, een deel van Nederland of een specifieke stad of plaats). Naast het definiëren van nieuwe Contingenten kunnen reeds voltooide berekeningen en resultaten worden ingezien op deze pagina.</li>
        <li>De laatste stap is <b>Resultaten</b>. Op deze pagina is zichtbaar welke woningen / adressen op de door u geselecteerde locatie binnen uw contingent vallen. Het is inzichtelijk gemaakt door middel van een interactieve kaart. Op de kaart is per woning of gebied aangegeven of het verduurzamingsconcept toepasbaar is of niet. Daarnaast is er ook algemene informatie beschikbaar over de woningen, o.a. over het bouwjaar, de oppervlakte en de omtrek van de woning.</li>
      </ol>

      <p>
        Het account en de inloggegevens zijn gekoppeld aan uw organisatie. De Clustertool is hierdoor gemakkelijk te gebruiken door meerdere collega’s. De resultaten van uitgevoerde berekeningen en gevormde Contigenten zijn voor iedereen binnen de organisatie zichtbaar en toegankelijk.
      </p>

      <h3>
        Succes met het vormen van uw contingent!
      </h3>
    </template>
  </Card>
</template>

<style scoped>

ol li {
  margin-bottom: 10px;
  margin-left: 10px;
}

</style>