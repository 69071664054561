<template>
  <div v-if="adminSettings">
    <ModelsDefaultVersions
      @update_version="updateVersion"
    />
  </div>

  <Button label="Opslaan" @click="saveSettings" />
</template>

<script setup>
import {ref} from "vue";
import {fetchData, putData} from "@/api";
import ModelsDefaultVersions from '@/components/settings/ModelsDefaultVersions.vue'

const adminSettings = ref(false);

const queryAdminSettings = async () => {
  const resp = await fetchData("admin_settings/");
  adminSettings.value = resp;
}
queryAdminSettings();

const saveSettings = async () => {
  console.log("Save admin settings...");
  await putData("admin_settings/", adminSettings.value);
}

const updateVersion = (version_data) => {
  const versionIndex = adminSettings.value['default_model_versions'].findIndex(version => version.model_name === version_data.model_name)
  if (versionIndex === -1) {
    adminSettings.value['default_model_versions'].push({
      model_name: version_data.model_name,
      version_name: version_data.version_name
    });
  } else {
    adminSettings.value['default_model_versions'][versionIndex].version_name = version_data.version_name;
  }
}

</script>

