<template>
  <div class="pb-2">
    <Card>
      <template #title>
        Contingent: definieer en start berekening
        <help-sidebar help-reference="Contingent: definieer en start berekening" />
      </template>
      <template #content>
        <label for="naam">Naam</label>
        <input
          id="naam"
          v-model="predictionName"
          type="text"
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary w-full"
        >
        <h4>Selecteer een getraind model</h4>
        <view-edit-select-table
          v-model:selected-row="selectedTrainingJob"
          :rows="trainedModels"
          :columns="trainedModelColumns"
          :badges="trainingResultsQualities"
          :custom-actions="trainedModelActions"
          :selectable-row="true"
          :loading="loadingTrainedModels"
          @select="onSelectTrainedModel"
        />
        <h4>Selecteer gebied waarvoor het contingent bepaald moet worden</h4>
        <div>
          <div class="field-radiobutton">
            <RadioButton v-model="selected_area" input-id="city" name="city" value="city" />
            <label for="city" class="mr-4">Stad</label>
            <city-select
              v-if="selected_area === 'city'"
              v-model:selected_city="selected_city"
            />
          </div>
          <!--          <div class="field-radiobutton">-->
          <!--            <RadioButton v-model="selected_area" input-id="province" name="province" value="province" disabled="true" />-->
          <!--            <label for="province">Provincie</label>-->
          <!--            <province-select-->
          <!--              v-if="selected_area === 'province'"-->
          <!--              v-model:selected_province="selected_province"-->
          <!--              class="ml-2"-->
          <!--              @update:selectedProvince="(province) => {selected_province.value = province;}"-->
          <!--            />-->
          <!--          </div>-->
          <div class="field-radiobutton">
            <RadioButton v-model="selected_area" input-id="country" name="country" value="country" />
            <label for="country">Heel Nederland</label>
          </div>
        </div>
        <div v-if="hasRole('admin')">
          <h4>Selecteer predictie model en versie</h4>
          <Dropdown
            v-model="selectedPredictionModelName"
            :options="predictionModels"
            option-label="name"
            option-value="name"
            input-id="prediction-model"
            class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary my-1"
          />
          <label for="prediction-model" class="ml-1">
            Selecteer predictie model {{ trainedModelMessage }}
          </label>
          <br>
          <Dropdown
            v-model="selectedPredictionModelVersion"
            :options="predictionModelVersions[selectedPredictionModelName]"
            option-label="description"
            option-value="name"
            input-id="prediction-model-versie"
            class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary my-1"
          />
          <label for="prediction--model-versie" class="ml-1">
            Selecteer model versie {{ trainedVersionMessage }}
          </label>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            class="flex align-items-center justify-content-center m-2"
            label="Start berekening"
            icon="pi pi-play"
            :disabled="buttonAdviceText != null"
            @click="onStartCalculation"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Card>

    <Card>
      <template #title>
        Contingent: lopende berekeningen en resultaten
        <help-sidebar help-reference="Contingent: lopende/voltooide berekeningen" />
      </template>
      <template #content>
        <view-edit-select-table
          :rows="predictionJobs"
          :columns="predictionJobsColumns"
          :page-size="10"
          :sort-option="new DataTableSortOption('updated', DataTableSortOrder.DESCENDING)"
          :deletable-row="true"
          :custom-actions="jobActions"
          :loading="loadingPredictionJobs"
          @delete="onRemovePredictionJob"
        />
      </template>
    </Card>
    <view-training-details
      v-if="trainedModelToView!==null"
      :training-job="trainedModelToView"
      @close="trainedModelToView=null"
    />
    <view-prediction-details
      v-if="predictionResultToView!==null"
      :prediction-job="predictionJobToView"
      :prediction-job-result="predictionResultToView"
      @close="predictionResultToView=null"
    />
  </div>
  <Dialog
    v-model:visible="showDivergentVersionDialog" class="w-6" header="Bevestig afwijkende versie"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3 text-4xl" />
      <br>
      <span>Gekozen predictie versie: <b>{{ selectedPredictionModelVersion }}</b></span>
      <br>
      <span>Versie gebruikt voor getraind model: <b>{{ selectedTrainingJob.training_setup.kfp_version }}</b></span>
      <br><br>
      <span>Wilt U voorspellen met een andere versie?</span>
    </div>
    <template #footer>
      <Button label="Nee" icon="pi pi-times" text @click="showDivergentVersionDialog = false" />
      <Button label="Ja" icon="pi pi-check" text class="p-button-danger" @click="startCalculation" />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="showAlternativeVersionDialog" class="w-6" header="Bevestig niet-standaard versie"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3 text-4xl" />
      <br>
      <span>Het gekozen getraind model heeft een niet-standaard (verouderde) versie.</span>
      <br><br>
      <span>Gekozen getraind model versie: <b>{{ selectedTrainingJob.training_setup.kfp_version }}</b></span>
      <br>
      <span>Standaard getraind model versie: <b>{{ defaultTrainingVersion }}</b></span>
      <br><br>
      <span>Als U met de standaard versie wilt voorspellen moet U eerst een nieuw model trainen.</span>
      <br>
      <span>Wilt U doorgaan met voorspellen?</span>
    </div>
    <template #footer>
      <Button label="Nee" icon="pi pi-times" text @click="showAlternativeVersionDialog = false" />
      <Button label="Ja" icon="pi pi-check" text class="p-button-danger" @click="startCalculation" />
    </template>
  </Dialog>
</template>

<script setup>
import {computed, ref} from "vue";
import HelpSidebar from "@/components/help/HelpSidebar"
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import CitySelect from "@/components/base-components/CitySelect";
import {deleteData, fetchData, fetchFile, postData} from "@/api";
import router from "@/router";
import {exportFileFromBloburl, getTrainingQualities} from "@/helpers/functions";
import ViewTrainingDetails from "@/components/training/ViewTrainingDetails";
import ViewPredictionDetails from "@/components/prediction/ViewPredictionDetails";
import {useKeycloak} from "@/keycloak/authentication";
import {DataTableSortOption, DataTableSortOrder} from "@/types";
import {errorToast, infoToast, successToast, toast} from "@/toastService";

const {hasRole} = useKeycloak();

const predictionName = ref("");
const selectedTrainingJob = ref();
const loadingTrainedModels = ref(true);
const trainedModelToView = ref(null);
const defaultTrainingVersion = ref(null);
const trainedModels = ref([]);
const trainedModelColumns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);
const trainingResultsQualities = ref(null);
const trainedModelMessage = ref("");
const trainedVersionMessage = ref("");

const loadingPredictionJobs = ref(true);
const predictionJobs = ref([]);
const predictionJobToView = ref([]);
const predictionResultToView = ref(null);

const predictionModels = ref([]);
const predictionModelVersions = ref({});
const selectedPredictionModelName = ref(null);
const selectedPredictionModelVersion = ref(null);

const selected_area = ref("city");
const selected_city = ref(null);
// const selected_province = ref("");

const showDivergentVersionDialog = ref(false);
const showAlternativeVersionDialog = ref(false);

const predictionJobsColumns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
  {field: 'progress_fraction', header: 'Voortgang', format: "fraction_is_percentage"}
]);

const queryTrainedModels = async () => {
  const training_jobs = await fetchData("training/job");
  // Only show training jobs with results and within quality limit
  trainedModels.value = training_jobs.filter((job) => {
    return job.training_result != null &&
        job.training_result.metrics.accuracy_class > 0;
  });
  trainingResultsQualities.value = getTrainingQualities(trainedModels.value);
};
queryTrainedModels().then(() => loadingTrainedModels.value = false);


const queryPredictionModels = async () => {
  predictionModels.value = await fetchData("prediction/models");
  if (predictionModels.value.length === 0) {
    return;
  }
  selectedPredictionModelName.value = predictionModels.value[0].name;
  if (!hasRole('admin')) {
    return;
  }
  for (const model of predictionModels.value) {
    predictionModelVersions.value[model.name] = [];
    for (let version of model.kfp_versions) {
      predictionModelVersions.value[model.name].push({name: version, description: version})
    }
  }
};
queryPredictionModels();

const queryDefaultTrainingVersion = async () => {
  const defaultKfpVersion = await fetchData("training/default_version");
  defaultTrainingVersion.value = defaultKfpVersion.version_name;
};
queryDefaultTrainingVersion();

const onSelectTrainedModel = () => {
  selectedPredictionModelVersion.value = selectedTrainingJob.value.training_setup.kfp_version;
  if (!hasRole('admin')) {
    return;
  }
  trainedModelMessage.value = " ('" + selectedTrainingJob.value.name + "' getraind met model '"
      + selectedTrainingJob.value.training_setup.kfp_name + "')";
  trainedVersionMessage.value = " ('" + selectedTrainingJob.value.name + "' getraind met versie '"
      + selectedTrainingJob.value.training_setup.kfp_version + "')";

  selectedPredictionModelVersion.value = null;
  for (const model of predictionModels.value) {
    predictionModelVersions.value[model.name] = [];
    for (let version of model.kfp_versions) {
      let description;
      if (selectedTrainingJob.value.training_setup.kfp_version === version.toString()) {
        description = version + " (trained)";
        selectedPredictionModelVersion.value = version;
      } else {
        description = version;
      }
      predictionModelVersions.value[model.name].push({name: version, description: description})
    }
  }
}

const buttonAdviceText = computed(() => {
  if (predictionName.value.replace(/\s/g, '') === '') {
    return "Vul naam in";
  } else if (!selectedTrainingJob.value) {
    return "Selecteer een getraind model";
  } else if (selected_area.value === "city" && !selected_city.value) {
    return "Selecteer een gebied";
  }
  return null;
});

const onStartCalculation = async () => {
  if (hasRole('admin')) {
    if (selectedTrainingJob.value.training_setup.kfp_version === selectedPredictionModelVersion.value) {
      await startCalculation()
    } else {
      showDivergentVersionDialog.value = true;
    }
  } else {
    if (selectedTrainingJob.value.training_setup.kfp_version === defaultTrainingVersion.value) {
      await startCalculation();
    } else {
      showAlternativeVersionDialog.value = true;
    }
  }
}

const startCalculation = async () => {
  showDivergentVersionDialog.value = false;
  showAlternativeVersionDialog.value = false;

  let payload = {
    name: predictionName.value,
    training_result_id: selectedTrainingJob.value.training_result.id,
    kfp_name: selectedPredictionModelName.value,
    kfp_version: selectedPredictionModelVersion.value,
  }
  if (selected_city.value) {
    payload['selected_city'] = selected_city.value.name;
  }

  infoToast("Predictie aanmaken...")

  // create training setup in db
  const ps_post = await postData("prediction/setup", payload);
  const ps_resp = await ps_post.json();

  if (ps_resp === null) {
    return;
  }

  // create and start prediction job
  await postData("prediction/job", {
    prediction_setup_id: ps_resp.id,
  });

  successToast(
      "Contingent berekening '" + predictionName.value + "' toegevoegd",
      "Getraind model: '" + selectedTrainingJob.value.name
  );
  predictionName.value = '';
  await queryPredictionJobs();
};

/**
 * Query prediction jobs from backend.
 * @returns {Promise<void>}
 */
const queryPredictionJobs = async () => {
  predictionJobs.value = await fetchData("prediction/job");
};
queryPredictionJobs().then(() => loadingPredictionJobs.value = false);

const onRemovePredictionJob = async (predictionJobId) => {
  let jobName = null;
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId) {
      jobName = predictionJob.name;
    }
  }
  const del_resp = await deleteData("prediction/job/" + predictionJobId);
  await queryPredictionJobs();

  if (del_resp.ok) {
    successToast("Contingent '" + jobName + "' verwijderd");
  } else {
    errorToast("Contingent '" + jobName + "' kan niet verwijderd worden");
  }
};


/**
 * Check status of job. This only checks the status in the API. It does not go to Kubeflow, because loading the results
 * can take too long. The jobchecker will check this.
 *
 */
const onCheckStatus = async (prediction_job_id) => {
  const prediction_job = await fetchData("prediction/job/" + prediction_job_id);

  const severity_map = {
    'Succeeded': 'success',
    'Failed': 'error'
  }
  let severity = 'warn'
  if (prediction_job.last_status in severity_map) {
    severity = severity_map[prediction_job.last_status];
  }

  toast(
      severity,
      "Model prediction job status: " + prediction_job.last_status,
      "Created: " + prediction_job.created_at +
      "\n\nScheduled: " + prediction_job.scheduled_at +
      "\n\nFinished: " + prediction_job.finished_at
  );
  await queryPredictionJobs();
};

const onDownloadList = async (predictionJobId) => {
  const contingentId = getContingentId(predictionJobId);
  if (contingentId) {
    let pred_name = '';
    for (const job of predictionJobs.value) {
      if (job.contingent_id === contingentId) {
        pred_name = job.name;
      }
    }
    const blobUrl = await fetchFile("prediction/contingent_csv/" + contingentId);
    exportFileFromBloburl(blobUrl, `${pred_name}.csv`)
  }
};

/**
 * Show results of prediction job on map. This will only work if the prediction job is finished.
 * @param predictionJobId
 */
const onShowResults = (predictionJobId) => {
  const contingentId = getContingentId(predictionJobId);
  if (contingentId) {
    router.push({
      name: 'MapResults',
      params: {contingentId: contingentId},
    });
  }
};

/**
 * Get the contingent id of a prediction job. This is used to download the results.
 * @param predictionJobId
 */
const getContingentId = (predictionJobId) => {
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId) {
      if ('prediction_result' in predictionJob && predictionJob.prediction_result !== null) {
        return predictionJob.prediction_result.id;
      }
    }
  }
  return null;
};

/**
 * Get the training job id of a prediction job. This is used to download the results.
 * @param trainingJobId
 */
const onViewTrainingDetails = async (trainingJobId) => {
  trainedModelToView.value = await fetchData("training/job/" + trainingJobId);
};

const onViewPredictionDetails = async (predictionJobId) => {
  predictionJobToView.value = predictionJobs.value.find(j => j.id === predictionJobId)
  predictionResultToView.value = (predictionResultToView.value = await fetchData("prediction/job_result/" + predictionJobId));
};

const jobSucceeded = (predictionJobId) => {
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId && predictionJob.last_status !== null) {
      return predictionJob.last_status.toUpperCase() === 'SUCCEEDED';
    }
  }
  return false;
};

const jobIsRunning = (predictionJobId) => {
  for (const predictionJob of predictionJobs.value) {
    if (predictionJob.id === predictionJobId && predictionJob.last_status !== null) {
      return predictionJob.last_status.toUpperCase() === 'CREATED' || predictionJob.last_status.toUpperCase() === 'RUNNING';
    }
  }
  return false;
};

const trainedModelActions = [
  {
    id: 1, icon: 'pi pi-eye', visible: () => true, handler: onViewTrainingDetails, tooltip: 'Bekijk details'
  }
];

const jobActions = [
  {
    id: 1, icon: 'pi pi-eye', visible: () => true, handler: onViewPredictionDetails, tooltip: 'Bekijk details'
  },
  {
    id: 2, icon: 'pi-sync', visible: jobIsRunning, handler: onCheckStatus, tooltip: 'Check status'
  },
  {
    id: 3, icon: 'pi-download', visible: jobSucceeded, handler: onDownloadList, tooltip: 'Download adressen'
  },
  {
    id: 4, icon: 'pi-map', visible: jobSucceeded, handler: onShowResults, tooltip: 'Bekijk op kaart'
  }
];

</script>

<style scoped>

</style>
