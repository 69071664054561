import {app} from '@/main';
import {ToastSeverity} from "primevue/api";

const lifeTime = 5000; // ms

export const toast = (severity, summary, detail = "") => {
    if (severity === ToastSeverity.SUCCESS) {
        successToast(summary, detail);
    }
    else if (severity === ToastSeverity.INFO) {
        infoToast(summary, detail);
    }
    else if (severity === ToastSeverity.ERROR) {
        errorToast(summary, detail);
    }
    else if (severity === ToastSeverity.WARN) {
        warningToast(summary, detail);
    }
    else {
        console.log("Toast severity not a valid option");
    }
}

export const infoToast = (summary, detail = "") => {
    app.config.globalProperties.$toast.add({summary: summary, detail: detail, severity: ToastSeverity.INFO, life: lifeTime});
}

export const successToast = (summary, detail) => {
    console.log(ToastSeverity.SUCCESS)
    app.config.globalProperties.$toast.add({summary: summary, detail: detail, severity: ToastSeverity.SUCCESS, life: lifeTime});
}

export const errorToast = (summary, detail = "") => {
    app.config.globalProperties.$toast.add({summary: summary, detail: detail, severity: ToastSeverity.ERROR, life: lifeTime});
}

export const warningToast = (summary, detail = "") => {
    app.config.globalProperties.$toast.add({summary: summary, detail: detail, severity: ToastSeverity.WARN, life: lifeTime});
}

export const clearToaster = () => {
    app.config.globalProperties.$toast.removeAllGroups();
}